.buy > section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 4vh;
  min-height: 65vh;
}

.buy .buy-link {
  text-decoration: none;
  margin-bottom: 8vh;
}

.buy .listing-buy {
  display: flex;
  padding-left: 1vw;
  padding-right: 1vw;
  justify-content: space-around;
  align-items: center;
  color: black;
  text-decoration: none;
}

.light-buy .listing-buy {
  background-color: #cacaca;
  box-shadow: 0px;
  border-radius: 7px;
}

.dark-buy .listing-buy {
  background-color: #939393;
  box-shadow: 1vw 1vh #7289DA;
  border-radius: 15px;
}

.buy .listing-buy:hover {
  opacity: 0.9;
}

.dark-buy > section > h1 {
  color: white;
}

.buy .listing-buy > article {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.buy .listing-buy section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buy .buy-top {
  text-overflow: ellipsis;
  overflow-x: hidden !important;
}

@media only screen and (max-width: 550px) {
  .buy .listing-buy {
    width: 90vw;
    height: 20vh;
    flex-direction: row-reverse;
  }

  .buy .listing-buy img {
    height: 15vh;
    min-width: 10vh;
    max-width: 30vh;
    border-radius: 35px;
  }

  .buy .buy-top {
    height: auto !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .buy .buy-top .condition {
    font-weight: normal;
    font-size: 2vh;
  }

  .buy .buy-top .price {
    font-weight: normal;
    font-size: 1.8vh;
  }

  .buy .listing-buy section {
    height: auto;
  }

  .buy .listing-buy section h1 {
    font-size: 2.5vh;
    text-overflow: clip;
    margin-bottom: 1vh;
    margin-top: 0px;
  }

  .buy .listing-buy section .description {
    display: none;
  }

  .buy .listing-buy section .location {
    font-size: 1.4vh;
    text-overflow: ellipsis;
  }

  .light-buy .comp-img {
    height: 1vh;
    width: 1vh;
    position: absolute;
    margin-top: 1vh;
    margin-left: 1vh;
    border-radius: 100%;
  }
}

@media only screen and (min-width: 550px) {
  .buy .listing-buy {
    width: 70vw;
    height: 35vh;
  }

  .buy .listing-buy img {
    height: 30vh;
    min-width: 10vh;
    max-width: 40vh;
    border-radius: 35px;
  }

  .buy .listing-buy section h1 {
    font-size: 200%;
    text-overflow: clip;
    margin-right: 2vw;
  }

  .buy .listing-buy section {
    height: 8vh;
  }

  .buy .buy-top {
    min-height: 10vh;
    max-height: 10vh;
    height: auto;
    max-width: 50vw;
    min-width: 20vw;
    width: 60vw;
  }

  .light-buy .comp-img {
    height: 2vh;
    width: 2vh;
    position: absolute;
    margin-top: 1.4vh;
    margin-left: 1.4vh;
    border-radius: 100%;
  }
}

.buy .listing-buy .buy-tag {
  margin-left: 1vw;
  height: 4vh;
  max-height: 10vh;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
}

.buy .listing-buy .buy-tag p {
  color: black;
  font-size: 80%;
  font-weight: bold;
}

.buy .listing-buy h2 {
  font-size: 1.2vw;
  margin-right: 3vw;
}

.buy .listing-buy > article > section {
  width: 35vw;
  overflow-y: hidden;
}

.buy .listing-buy > article > section > p {
  font-size: 1.0vw;
  color: black;
  overflow-x: hidden;
}

@media only screen and (min-width: 550px) and (max-width: 1525px) {
  .buy .listing-buy section h1 {
    font-size: 1.5vw;
  }
  .buy .listing-buy .buy-tag{
    font-size: 1vw;
  }
}