body {
  font-family: 'Petrona', serif;
  max-width: 100%;
  overflow-x: hidden;
  /* text-transform: lowercase; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 0;
  background-color: #333;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: rgb(107, 107, 107);
}

::placeholder {
  font-size: 1vw;
  color: #CACACA;
}

input:focus {
  outline: none;
}

.loading {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}