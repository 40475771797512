.lake {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.lake-photo {
  width: 110vw;
  height: 100vh;
  object-fit: cover;
  object-position: 100% 60%;
  z-index: -1;
  opacity: 0.9;
}

.lake h1, .lake h2, .lake-logo {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50vw;
}

.lake h1, .lake h2 {
  color: #CACACA;
  font-style: bold;
  width: 90vw;
}

.lake-logo {
  top: 32vh;
}

.lake h1, .lake h2, .lake-logo {
  margin-bottom: 3vh !important;
}

@media only screen and (max-width: 550px) {
  .lake h1 {
    top: 53vh;
    font-size: 5vh;
    color: rgb(255, 255, 255);
  }

  .lake h2 {
    top: 66vh;
    font-size: 3vh;
    color: rgb(255, 255, 255);
  }

  .lake-logo {
    height: 35vh;
  }
}

@media only screen and (min-width: 550px) {
  .lake h1 {
    top: 53vh;
    font-size: 5vw;
  }

  .lake h2 {
    top: 66vh;
    font-size: 3vw;
  }

  .lake-logo {
    height: 30vw;
  }
}