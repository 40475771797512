.navbar-main {
  width: 100vw;
  height: 10vh;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.light-navbar-main, .light-navbar-main button {
  background-color: #cacaca;
}

.dark-navbar-main, .dark-navbar-main button {
  background-color: #939393;
}

.navbar-main img {
  height: 2.8vh;
}

.navbar-main button {
  border: none;
}

.navbar-main img:hover {
  opacity: 0.7;
}

.navbar-main .search {
  margin-left: 6vw;
  margin-top: 0;
  margin-right: 2vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navbar-main .search input {
  height: 4vh;
  border: 0px;
  width: 40vw;
  margin: 3vh;
  margin-top: 0vh;
  margin-bottom: 0vh;
}

.navbar-main .search form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.light-navbar-main .search input{
  background-color: #dbdbdb;
}

.navbar-main .search button {
  cursor: pointer;
  padding: 0px;
}

.navbar-main .search img {
  height: 3vh;
}

.dark-navbar-main .search input {
  background-color: #B9B9B9;
}

.navbar-main .search input::placeholder {
  color: black;
  font-size: 1.3vh;
}

.navbar-main article {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
}

.navbar-main hr {
  width: 3vw;
}

.light-navbar-main hr {
  border: 2px solid #F1965E;
}

.dark-navbar-main hr {
  border: 2px solid #7289DA;
}

.navbar-main article .link-nav-main {
  color: black;
  width: 7.2vw;
  height: 1.5vh;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1vh;
  text-decoration: none;
}

.navbar-main .link-nav-main:hover {
  opacity: 0.7;
}

.navbar-main section {
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
}

.navbar-main .link-main {
  width: 18vw;
  font-size: 1.7vw;
  text-decoration: none;
  color: black;
  margin-left: 2vw;
}

.navbar-main .link-main:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 550px) {
  .navbar-main .links-main {
    display: none;
  }

  .navbar-main .search input {
    width: 65vw;
    margin-left: 0px;
  }

  .navbar-main .link-main {
    font-size: 5vw;
    width: 80vw;
  }

  .navbar-main .menu-nav {
    margin-left: 4vw;
    margin-right: 4vw;
  }

  .navbar-main {
    display: flex;
    align-items: center;
  }

  .navbar-main .search-desktop {
    display: none;
  }

  .navbar-main .exit {
    opacity: 0.7;
  }

  .navbar-main .nav-menu {
    position: fixed;
    top: 8vh;
    left: 5vw;
    height: 80vh;
    background-color: #b1b1b1;
    width: 90vw;
    z-index: 10;
  }

  .navbar-main .nav-menu .link-nav-main {
    font-size: 4vh;
    width: 50vw;
    margin-top: 5vh;
  }
}

@media only screen and (min-width: 550px) {
  .navbar-main .links-main {
    width: 24.3vw;
    height: 3vw;
    margin-top: 5vh;
    font-size: 1vw;
  }

  .navbar-main .menu-nav {
    display: none;
  }

  .navbar-main .search-mobile {
    display: none;
  }
}