@media only screen and (max-width: 550px) {
  .account {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }

  .account .profile {
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
  }

  .account .pfp {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100vw;
    margin-top: 2vh;
  }

  .account .pfp img {
    height: 20vh;
    border-radius: 150px;
  }

  .account .pfp p {
    font-size: 2.5vh;
    font-weight: bold;
  }

  .account .profile-info {
    height: 30vh;
    width: 85vw;
    border-radius: 30px;
    align-self: center;
    margin-bottom: 5vh;
    padding-bottom: 2vh;
  }

  .light-account .profile-info {
    background: #eeeeee;
  }

  .dark-account .profile-info {
    background-color: #939393;
    color: white;
  }

  .account .profile-info h1 {
    font-size: 2.4vh;
    text-align: left;
    margin-top: 4vh;
    margin-left: 2vw;
    font-weight: bold;
  }

  .account .profile-info h2 {
    font-size: 2.2vh;
    margin-left: 3vw;
    font-weight: normal;
  }

  .account .profile-info p {
    font-size: 2vh;
    display: inline;
  }

  .account .settings {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 85vw;
    height: 60vh;
    border-radius: 30px;
  }

  .light-account .settings {
    background: #eeeeee;
  }

  .dark-account .settings {
    background-color: #939393;
    color: white;
  }

  .account .settings h1 {
    font-weight: bold;
    font-size: 2.3vh;
    margin-left: 3vh;
  }

  .account .settings h2 {
    font-weight: normal;
    font-size: 2vh;
    margin-left: 5vh;
  }

  .account .settings section {
    display: flex;
    align-items: center;
  }

  .account .settings input[type="radio"] {
    background-color: #e3e3e3;
    -ms-transform: scale(1); /* IE */
    -moz-transform: scale(1); /* FF */
    -webkit-transform: scale(1); /* Safari and Chrome */
    -o-transform: scale(1); /* Opera */
    margin-right: 1vw;
    margin-left: 4vw;
    cursor: pointer;
  }

  .account .settings input[type="radio"]:checked {
    color: #e3e3e3;
  }

  .account .settings section button {
    width: 8vw;
    font-size: 2vh;
    height: 3vh;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 0px;
    cursor: pointer;
    background-color: #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .account .settings p {
    font-size: 2vh;
  }

  .account input[type="text"] {
    border: 0px;
    margin-left: 2vw;
    height: 4vh;
    width: 45vw;
    padding-left: 0.5vw;
  }

  .account input::placeholder {
    color: black;
    font-size: 1.5vh;
  }

  .light-account input {
    background-color: #e3e3e3;
  }

  .dark-account input {
    background-color: #c4c4c4;
  }

  .account h1 {
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
  }

  .light-account h1 {
    color: black;
  }

  .dark-account h1 {
    color: white;
  }

  .settings > button {
    font-size: 1.8vh;
    position: absolute;
    bottom: -30vh;
    background-color: #e3e3e3;
    border: 0px;
    right: 50vw;
    height: 6vh;
    padding-left: 1.8vw;
    padding-right: 1.8vw;
    border-radius: 18px;
    cursor: pointer;
  }

  .settings .save-changes {
    right: 13vw;
  }

  .settings button:hover {
    opacity: 0.8;
  }

  .account .links {
    display: flex;
    flex-direction: row;
    width: 100vw;
  }

  .account .links article {
    height: 10vh;
  }

  .account .links p {
    font-size: 2vh;
    cursor: pointer;
  }

  .light-account .links hr {
    border: 2px solid #f1965e;
  }

  .dark-account .links hr {
    border: 2px solid #7289da;
  }

  .account .pt h2 {
    font-size: 2.2vh;
  }

  .account .pt p {
    display: inline;
    font-size: 1.8vh;
  }

  .account .pt input::placeholder {
    font-size: 0.8vw;
  }

  .account .pt input {
    width: 32vw;
  }
}

@media only screen and (min-width: 550px) {
  .account {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 90vh;
  }

  .account .profile {
    height: 85vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .account .pfp {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw;
  }

  .account .pfp img {
    height: 25vh;
    border-radius: 150px;
  }

  .account .pfp p {
    font-size: 1.4vw;
    font-weight: bold;
  }

  .account .profile-info {
    height: 50vh;
    width: 25vw;
    border-radius: 30px;
  }

  .light-account .profile-info {
    background: #eeeeee;
  }

  .dark-account .profile-info {
    background-color: #939393;
    color: white;
  }

  .account .profile-info h1 {
    font-size: 1.8vw;
    text-align: left;
    margin-top: 4vh;
    margin-left: 2vw;
    font-weight: bold;
  }

  .account .profile-info h2 {
    font-size: 1.4vw;
    margin-left: 3vw;
    font-weight: normal;
  }

  .account .profile-info p {
    font-size: 1.2vw;
    display: inline;
  }

  .account .settings {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 58vw;
    height: 85vh;
    border-radius: 30px;
  }

  .light-account .settings {
    background: #eeeeee;
  }

  .dark-account .settings {
    background-color: #939393;
    color: white;
  }

  .account .settings h1 {
    font-weight: bold;
    font-size: 2.5vw;
    margin-left: 3vw;
  }

  .account .settings h2 {
    font-weight: normal;
    font-size: 2vw;
    margin-left: 8vw;
  }

  .account .settings section {
    display: flex;
    align-items: center;
  }

  .account .settings input[type="radio"] {
    background-color: #e3e3e3;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    margin-right: 1vw;
    margin-left: 4vw;
    cursor: pointer;
  }

  .account .settings input[type="radio"]:checked {
    color: #e3e3e3;
  }

  .account .settings section button {
    width: 5vw;
    font-size: 2vw;
    height: 5vh;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 0px;
    cursor: pointer;
    background-color: #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .account .settings p {
    font-size: 2vw;
  }

  .account input[type="text"] {
    border: 0px;
    margin-left: 2vw;
    height: 4vh;
    width: 20vw;
    padding-left: 0.5vw;
  }

  .account input::placeholder {
    color: black;
  }

  .light-account input {
    background-color: #e3e3e3;
  }

  .dark-account input {
    background-color: #c4c4c4;
  }

  .account h1 {
    text-align: center;
    font-size: 2vw;
    font-weight: 400;
  }

  .light-account h1 {
    color: black;
  }

  .dark-account h1 {
    color: white;
  }

  .settings > button {
    font-size: 1vw;
    position: absolute;
    bottom: 10vh;
    background-color: #e3e3e3;
    border: 0px;
    right: 25vw;
    height: 7vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    border-radius: 18px;
    cursor: pointer;
  }

  .settings .save-changes {
    right: 13vw;
  }

  .settings button:hover {
    opacity: 0.8;
  }

  .account .links {
    display: flex;
    flex-direction: column;
    width: 6vw;
  }

  .account .links article {
    height: 10vh;
  }

  .account .links p {
    font-size: 1vw;
    cursor: pointer;
  }

  .light-account .links hr {
    border: 2px solid #f1965e;
  }

  .dark-account .links hr {
    border: 2px solid #7289da;
  }

  .account .pt h2 {
    font-size: 1.5vw;
  }

  .account .pt p {
    display: inline;
    font-size: 1vw;
  }

  .account .pt input::placeholder {
    font-size: 0.8vw;
  }
}
