.err {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.err p, .err .error-link {
  font-size: 3vw;
  color: white;
}