.outer-item {
  overflow: auto;
  max-height: 1000vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item .item-link {
  text-decoration: none;
}

.light-item {
  background-color: #dbdbdb;
}

.dark-item {
  background-color: #3E3E3E;
}

.item {
  border-radius: 39px;
  display: flex;
  justify-content: space-evenly;
}

.light-item .item {
  box-shadow: 0.6vw 1vh rgb(107, 107, 107);
}

.dark-item .item {
  box-shadow: 1vw 1.5vh #7289DA;
}

.light-item .item, .light-item .item article button {
  background-color: #cacaca;
}

.dark-item .item, .dark-item .item article button {
  background-color: #939393;
}

.item .item-error {
  font-size: 4vw;
  color: rgb(179, 2, 2);
  align-self: center;
  width: 70vw;
  justify-self: center;
  text-align: center;
}

.item article {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item img {
  border-radius: 15px;
  object-fit: cover;
}

@media only screen and (max-width: 550px) {
  .item {
    width: 95vw;
    min-height: 80vh;
    margin-top: 2vh;
    margin-bottom: 5vh;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 3vh;
  }

  .item section {
    width: auto;
  }

  .item h1 {
    font-size: 3vh;
  }

  .item p {
    font-size: 2vh;
    color: black;
  }

  .item img {
    width: 40vw;
    max-height: 45vh;
  }

  .item article {
    height: 35vh;
    align-self: center;
  }

  .item .item-wrap {
    max-width: 70vw;
    word-wrap: break-word;
  }

  .item .item-contact p {
    font-size: 1.5vh;
  }


  .item a {
    color: black;
    font-size: 2vh;
  }

  .item .item-contact {
    width: 30vw;
    border: 0px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    align-self: center;
    position: fixed;
    bottom: 6vh;
    right: 8vw;
  }

  .item .item-right {
    width: 40vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .item .team {
    max-width: 40vw;
  }
}

@media only screen and (min-width: 550px) {
  .item {
    width: 70vw;
    min-height: 80vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }


  .item section {
    width: 30vw;
    min-height: 80vh;
  }

  .item h1 {
    font-size: 2.4vw;
  }

  .item p {
    font-size: 1.8vw;
    color: black;
  }

  .item img {
    width: 20vw;
    max-height: 45vh;
  }

  .item .item-wrap {
    max-width: 20vw;
    word-wrap: break-word;
  }

  .item .item-contact p {
    font-size: 2vw;
  }


  .item a {
    color: black;
    font-size: 2vw;
  }

  .item .item-contact {
    width: 18vw;
    border: 0px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
  }

  .item .item-right {
    width: 20vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-left: 5vw;
  }

  .item .team {
    width: 20vw;
  }
}

.item article button {
  border: 0px;
  height: 100%;
}

.item article button:hover {
  opacity: 0.8;
}

.item article .item-icon-r:hover {
  opacity: 0.7;
}

.item article .item-icon-l:hover {
  opacity: 0.7;
}

@keyframes move {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(20px);
  }
}

.light-item .item .item-contact {
  background-color: #63E100;
}

.dark-item .item .item-contact {
  background-color: #63E100;
}

.item .item-contact:hover {
  opacity: 0.8;
}

.item .team {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.item .team img {
  width: 4vw;
  height: 4vw;
  margin-right: 2vw;
}