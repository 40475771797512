.privacy {
    background-color: #3E3E3E;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.privacy p{
    color: white;
    font-size: 1.5vw;
}

.privacy img {
    height: 10vh;
    width: 10vh;
}