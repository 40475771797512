.welcome-navbar {
  position: sticky;
  top: 0;
  background-color: rgba(0,0,0,.1);
  background: url("../../../assets/robot-lake.png");
  margin: 0;
  padding: 0;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 3;
}

@media only screen and (max-width: 550px) {
  .welcome-navbar > * {
    font-style: normal;
    font-weight: bold;
    font-size: 2vh;
    color: white;
    margin: 0;
    margin-right: 2vh;
    margin-top: 10vh;
    text-decoration: none;
  }
}

@media only screen and (min-width: 550px) {
  .welcome-navbar > * {
    font-style: normal;
    font-weight: bold;
    font-size: 1.5vw;
    color: white;
    margin: 0;
    margin-right: 2vw;
    margin-top: 10vh;
    text-decoration: none;
  }
}