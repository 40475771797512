.add .req {
  width: inherit;
  display: flex;
  justify-content: space-evenly;
  margin-top: 5vh;
}

@media only screen and (max-width: 550px) {
  .add, .add .add-submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .add .add-submitted {
    width: 100vw;
    height: 100vh;
    justify-content: center;
  }

  .add .add-submitted p {
    font-size: 4vw;
  }

  .add > p {
    font-size: 2.4vh;
  }

  .add > article {
    border-radius: 39px;
    width: 90vw;
    margin-bottom: 5vh;
  }

  .light-add > article {
    background-color: #cacaca;
    box-shadow: 0.5vw 0.5vh rgb(107, 107, 107);
  }

  .dark-add > article {
    background-color: #939393;
    box-shadow: 1vw 1vh #7289DA;
  }

  .add article form {
    display: flex;
    flex-direction: column;
  }

  .add .add-listing-centered {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3vh;
  }

  .add form > p {
    justify-self: flex-end;
    align-self: center;
    font-size: 1vh;
  }

  .add input {
    border: 0px;
  }

  .light-add input, .light-add textarea, .light-add select {
    background-color: #dbdbdb;
  }

  .dark-add input, .dark-add textarea, .dark-add select {
    background-color: #C4C4C4;
  }

  .add .add-listing-centered input {
    height: 7vw;
    width: 75vw;
    margin-top: 2vh;
  }

  .add .currency-input input {
    width: 50vw;
  }

  .add .currency-input select{
    width: 22vw;
    height: 7vw;
    border: 0px;
    margin-right: 3vw;
  }

  .add .add-listing-centered textarea {
    border: 0px;
    width: 75vw;
    height: 20vh;
    margin-top: 3vh;
  }

  .add textarea:focus, input:focus{
    outline: 0;
  }

  .add ::placeholder {
    font-size: 1.5vh;
    color: black;
  }

  .add button:hover {
    opacity: 0.7;
  }

  .add form > button {
    background-color: #63E100;
    border: 0px;
    height: 5vh;
    width: 30vw;
    font-size: 1.6vh;
    border-radius: 26px;
    margin-top: 2vh;
    justify-self: flex-end;
    align-self: center;
  }

  .add input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .add .add-listing-left {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 3vh;
    margin-left: 2vw;
  }

  .add .add-listing-left input[type="text"], .add .add-listing-left input[type="number"] {
    margin-top: 2vh;
    width: 30vw;
    height: 5vh;
  }

  .add .add-listing-left section {
    display: flex;
    align-items: center;
  }

  .add .add-listing-left > p {
    font-size: 2vh;
  }

  .add .add-listing-left input[type="checkbox"] {
    background-color: #eee;
    -ms-transform: scale(1.3); /* IE */
    -moz-transform: scale(1.3); /* FF */
    -webkit-transform: scale(1.3); /* Safari and Chrome */
    -o-transform: scale(1.3); /* Opera */
    margin-right: 1vw;
    margin-left: 1vw;
  }

  .add .add-listing-left label {
    font-size: 1.8vh;
  }

  .light-add .add-listing-left label {
    color: black;
  }

  .dark-add .add-listing-left label {
    color: white;
  }

  .add .add-error {
    font-size: 1.5vh;
    color: rgb(179, 2, 2);
  }

  .add .tags {
    width: 90vw;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 4vh;
  }

  .add .tags > section {
    width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .add .tags > section > section > label {
    font-size: 2vh;
  }

  .add .tags p {
    font-size: 1.8vh;
  }

  .add input[type="radio"] {
    background-color: #eee;
    -ms-transform: scale(1); /* IE */
    -moz-transform: scale(1); /* FF */
    -webkit-transform: scale(1); /* Safari and Chrome */
    -o-transform: scale(1); /* Opera */
    margin-right: 1vw;
    margin-left: 1vw;
  }

  .add .upload-pictures {
    margin-top: 6vh;
    width: 83vw;
    overflow-x: scroll;
    align-self: center;
    display: flex;
    flex-direction: row;
  }

  .add .upload-pictures img, .add .upload-pictures article {
    margin-right: 2vw;
    height: 10vh;
    border-radius: 35px;
  }

  .add .upload-pictures article {
    background: #C4C4C4;
    box-sizing: border-box;
    font-size: 2vh;
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .light-add .upload-pictures article {
    border: 7px dashed rgb(107, 107, 107);
  }

  .dark-add .upload-pictures article {
    border: 7px dashed #7289DA;
  }

  .add input[type="file"] {
    display: none;
  }

  .add .custom-file-upload {
    border: 0px;
    display: inline-block;
    cursor: pointer;
    font-size: 1.8vh;
  }

  .add .tags article > label {
    font-size: 1.2vh;
  }
}

@media only screen and (min-width: 550px) {
  .add, .add .add-submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .add .add-submitted {
    width: 100vw;
    height: 100vh;
    justify-content: center;
  }

  .add .add-submitted p {
    font-size: 4vw;
  }

  .add > p {
    font-size: 2vw;
  }

  .add > article {
    border-radius: 39px;
    width: 65vw;
    margin-bottom: 10vh;
  }

  .light-add > article {
    background-color: #cacaca;
    box-shadow: 0.5vw 0.5vh rgb(107, 107, 107);
  }

  .dark-add > article {
    background-color: #939393;
    box-shadow: 1vw 1vh #7289DA;
  }

  .add article form {
    display: flex;
    flex-direction: column;
  }

  .add .add-listing-centered {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3vh;
  }

  .add form > p {
    justify-self: flex-end;
    align-self: center;
    font-size: 1vw;
  }

  .add input {
    border: 0px;
  }

  .light-add input, .light-add textarea, .light-add select {
    background-color: #dbdbdb;
  }

  .dark-add input, .dark-add textarea, .dark-add select {
    background-color: #C4C4C4;
  }

  .add .add-listing-centered input {
    height: 2.5vw;
    width: 45vw;
    margin-top: 3vh;
  }

  .add .currency-input input {
    width: 30vw;
  }

  .add .currency-input select{
    width: 12vw;
    height: 2.5vw;
    border: 0px;
    margin-right: 3vw;
  }

  .add .add-listing-centered textarea {
    border: 0px;
    width: 50vw;
    height: 20vh;
    margin-top: 4vh;
  }

  .add textarea:focus, input:focus{
    outline: 0;
  }

  .add ::placeholder {
    font-size: 1vw;
    color: black;
  }

  .add button:hover {
    opacity: 0.7;
  }

  .add form > button {
    background-color: #63E100;
    border: 0px;
    height: 9vh;
    width: 12vw;
    font-size: 1.4vw;
    border-radius: 26px;
    margin-top: 2vh;
    justify-self: flex-end;
    align-self: center;
  }

  .add input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .add .add-listing-left {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 3vh;
    margin-left: 2vw;
  }

  .add .add-listing-left input[type="text"], .add .add-listing-left input[type="number"] {
    margin-top: 2vh;
    width: 15vw;
    height: 5vh;
  }

  .add .add-listing-left section {
    display: flex;
    align-items: center;
  }

  .add .add-listing-left > p {
    font-size: 2vw;
  }

  .add .add-listing-left input[type="checkbox"] {
    background-color: #eee;
    -ms-transform: scale(1.8); /* IE */
    -moz-transform: scale(1.8); /* FF */
    -webkit-transform: scale(1.8); /* Safari and Chrome */
    -o-transform: scale(1.8); /* Opera */
    margin-right: 1vw;
    margin-left: 1vw;
  }

  .add .add-listing-left label {
    font-size: 1.6vw;
  }

  .light-add .add-listing-left label {
    color: black;
  }

  .dark-add .add-listing-left label {
    color: white;
  }

  .add .add-error {
    font-size: 1.5vw;
    color: rgb(179, 2, 2);
  }

  .add .tags {
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 4vh;
  }

  .add .tags > section {
    width: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .add .tags > section > section > label {
    font-size: 1vw;
  }

  .add .tags p {
    font-size: 1.8vw;
  }

  .add input[type="radio"] {
    background-color: #eee;
    -ms-transform: scale(1.8); /* IE */
    -moz-transform: scale(1.8); /* FF */
    -webkit-transform: scale(1.8); /* Safari and Chrome */
    -o-transform: scale(1.8); /* Opera */
    margin-right: 1vw;
    margin-left: 1vw;
  }

  .add .upload-pictures {
    margin-top: 6vh;
    width: 55vw;
    overflow-x: scroll;
    align-self: center;
    display: flex;
    flex-direction: row;
  }

  .add .upload-pictures img, .add .upload-pictures article {
    margin-right: 2vw;
    height: 20vh;
    border-radius: 35px;
  }

  .add .upload-pictures article {
    background: #C4C4C4;
    box-sizing: border-box;
    font-size: 1vw;
    width: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .light-add .upload-pictures article {
    border: 7px dashed rgb(107, 107, 107);
  }

  .dark-add .upload-pictures article {
    border: 7px dashed #7289DA;
  }

  .add input[type="file"] {
    display: none;
  }

  .add .custom-file-upload {
    border: 0px;
    display: inline-block;
    cursor: pointer;
    font-size: 1.4vw;
  }

}
